.container {
  background-color: black;
  color: white;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .containerThankYouPage {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .directToAppDownloadContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    //background-color: rgba(256, 256, 256, 0.1);
    background-color: black;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 20px;
  }

  .btnHearBack {
    width: 80px;
    height: 36px;
    background-color: rgb(76, 76, 143);
    border-style: none;
    font-size: 12px;
    margin: 8px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
    cursor: pointer;
    outline: none;
    transition: 0.5s all;
  }
  .btnHearBack:active {
    transform: scale(0.95);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    background-color: #04aa6d !important;
  }
}
.butterflyImg {
  position: absolute;
  top: 120px;
}
