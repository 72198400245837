.container {
  color: white;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../../public/images/background.png');
  background-repeat: no-repeat;
  background-size: cover;

  .userDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 75vh;
    justify-content: center;
  }

  .callBtn {
    width: 132px;
    height: 48px;
    border-radius: 15px;
    background-color: green;
    border-style: none;
    font-size: 18px;
    margin: 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.5s all;
  }
  .callBtn:active {
    transform: scale(0.95);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    background-color: #04aa6d !important;
  }

  .eachUserDetail {
    display: flex;
    align-items: center;
    margin: 10px 0px;

    & h4 {
      width: auto;
      text-align: left;
    }

    & h5 {
      width: 150px;
      text-align: center;
    }
  }
  .genericValues ul li {
    display: flex;
    align-items: center;
    & h4 {
      width: auto;
      text-align: left;
      padding-left: 10px;
      margin: 0px;
    }

    & h5 {
      width: 150px;
      text-align: center;
      margin: 0px;
    }
  }
  .userDetailsInfo {
    display: flex;
    flex-direction: column;
    padding: 15px;
  }

  .directToAppDownloadContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    background-color: black;
    padding: 20px;
    border-radius: 20px;
    box-sizing: content-box;
  }

  .cancelCallBtn {
    width: 72px;
    height: 36px;
    background-color: red;
    border-style: none;
    font-size: 20px;
    margin: 16px auto;
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
    cursor: pointer;
    outline: none;
    transition: 0.5s all;
  }
  .cancelCallBtn:active {
    transform: scale(0.95);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    background-color: #04aa6d !important;
  }
}

.callOptionModal {
  padding: 30px;
  display: flex;
  flex-direction: row;

  .modalBtn {
    margin: 5px;
    background-color: green;
    color: white;
    border-style: none;
    border-radius: 10px;
    padding: 5px;
  }
}
