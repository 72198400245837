.sendMsgToUser {
  font-size: 12px;
  text-align: center;

  .sendMsgBtn {
    width: 80px;
    height: 36px;
    background-color: rgb(76, 76, 143);
    border-style: none;
    font-size: 10px;
    margin: 8px auto;
    display: flex;
    align-items: center;
    border-radius: 5px;
    box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
    cursor: pointer;
    outline: none;
    transition: 0.5s all;
  }
  .sendMsgBtn:active {
    transform: scale(0.95);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    background-color: #04AA6D !important;
  }
}

// @keyframes spin-anim {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }
// .loading-spinner {
//   width: 44px;
//   height: 44px;
//   border: 4px solid;
//   border-color: #3d5af1 transparent #3d5af1 transparent;
//   border-radius: 50%;
//   animation: spin-anim 1.2s linear infinite;
// }

// .spinner-container {
//   display: grid;
//   justify-content: center;
//   align-items: center;
//   height: 144px;
// }

.msgSent-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 144px;
}
